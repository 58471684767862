<script>
import axios from "axios";
import Swal from "sweetalert2";
import routes from "@/router/routes";

export default {
  name: "ManageRole",
  data() {
    return {
      role: {},
      role_pages: [],
      routes: routes,
      menus_data: [],
    }
  },
  components: {
    // PageItem
  },
  methods: {
    show(role) {
      this.role = role
    },

    async getRoleDetails() {
      try {
        let {data} = await axios.get(`/user/roles/${this.role.id}/`)
        this.role_pages = (data.pages || []).map(page => {
          return {
            name: page.name,
            router_name: page.router_name,
          }
        })
        let allowed_pages = this.role_pages.map(a => a.router_name)
        this.menus_data = this.routes.map(route => {
          let children;

          if (route.children) {
            children = route.children.map(child => ({
              ...child,
              is_selected: allowed_pages.includes(child.name)
            }));
          }

          return {
            path: route.path,
            name: route.name,
            meta: {...(route.meta || {})},
            children: children,
            is_selected: children ? children.every(child => child.is_selected) : false
          };
        });
      } catch {
        alert("Error while getting role details")
      }
    },

    async submitChanges() {
      try {
        await axios.put(`/user/change-permission/${this.role.id}/`, {
          pages: this.role_pages
        })
        await Swal.fire({
          icon: 'success',
          title: 'Role updated successfully',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Close',
          cancelButtonText: 'Continue Editing',
        }).then((response) => {
          if (response && response.isConfirmed) {
            document.querySelector('#ManageRoleCanvas .btn-close').click()
          }
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },

    getDropdownMenus(route) {
      if (!route) return []
      return route.children || []
    },

    selectPageWithChildren(page) {
      if (!page) return
      let children = this.getDropdownMenus(page)
      page.is_selected = !page.is_selected
      children.forEach(child => {
        child.is_selected = page.is_selected
      })
    },
    pageIsChecked(route) {
      route.is_selected = this.getDropdownMenus(route).every(obj => obj.is_selected === true)
      return route.is_selected
    }
  },
  watch: {
    role() {
      this.role_pages = []
      this.getRoleDetails()
    },
    menus_data: {
      handler(newData) {
        this.role_pages = [];
        (newData || []).forEach(route => {
          if (route.is_selected) {
            (route.children || []).forEach(child => {
              this.role_pages.push({
                name: child.nameInMenu,
                router_name: child.name,
              })
            })
          } else {
            (route.children || []).forEach(child => {
              if (child.is_selected) {
                this.role_pages.push({
                  name: child.nameInMenu,
                  router_name: child.name,
                })
              } else {
                this.role_pages = this.role_pages.filter(page => page.router_name !== child.name)
              }
            })
          }
        })
      },
      deep: true
    }
  },
}
</script>

<template>

  <div class="offcanvas offcanvas-start " tabindex="-1" id="ManageRoleCanvas"
       aria-labelledby="ManageRoleCanvasLabel">
    <div class="offcanvas-body">

      <form @submit.prevent="submitChanges()">
        <div class="mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class="fw-semibold mb-0">{{ role.name }} </h3>
            <button type="button" class="btn-close text-reset"
                    data-bs-dismiss="offcanvas" aria-label="Close">

            </button>
          </div>
          <div>
            <div>
              <i class="mdi mdi-record text-success fs-5"></i>
              <span class="fs-5">- Access Granted</span>
            </div>
            <div>
              <i class="mdi mdi-record text-danger fs-5"></i>
              <span class="fs-5">- Access Denied</span>
            </div>
          </div>
        </div>

        <div class="verti-sitemap mb-5">
          <ul class="list-unstyled mb-0">
            <li class="p-0 parent-title">
              <b-link href="javascript: void(0);"
                      class="fw-medium fs-5">Menu
              </b-link>
            </li>
            <li>
              <div class="first-list  pt-1" v-for="(route, index) in menus_data" :key="`section_${route.name}`">
                <div class="list-wrap" v-if="route.meta.sectionTitle">
                  <b-link class="fw-medium text-primary fs-5"><i
                      class="ri-airplay-line me-1 align-bottom"></i>
                    {{ route.meta.sectionTitle }}
                  </b-link>
                </div>
                <ul class="second-list list-unstyled">
                  <template v-if="getDropdownMenus(route).length === 0">
                    <li class="align-middle" v-for="(page) in route.children" :key="`page_${page.name}`">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="formCheck1">
                        <label class="form-check-label fw-normal" for="formCheck1">
                          {{ page.nameInMenu || page.name }} | {{ page.is_selected }}
                        </label>
                      </div>
                    </li>
                  </template>
                  <template v-else>
                    <li class="align-middle">
                      <a class="fs-5 pe-0">
                        <div class="form-check">
                          <input @change="selectPageWithChildren(route)" class="form-check-input" type="checkbox"
                                 :id="`menu_${index}`"
                                 :checked="pageIsChecked(route)">
                          <label class="form-check-label fw-medium" :for="`menu_${index}`">
                            {{ route.meta.title }}
                          </label>
                        </div>
                      </a>
                      <ul class="second-list list-unstyled">
                        <li v-for="(page, page_index) in getDropdownMenus(route)" :key="`page_${page.name}`">
                          <a class="d-flex justify-content-between align-items-center fs-5 pe-0">
                            <div class="form-check">
                              <input
                                  @change="selectPageWithChildren(page)"
                                  :checked="page.is_selected"
                                  class="form-check-input" type="checkbox" :id="`menu_${index}page_${page_index}`">
                              <label class="form-check-label fw-normal"
                                     :for="`menu_${index}page_${page_index}`"
                              >
                                {{ page.nameInMenu || page.name }}
                              </label>
                            </div>
                            <i
                                :class="{
                                       'text-success': page.is_selected,
                                       'text-danger': !page.is_selected,
                                     }"
                                class="mdi mdi-circle-medium fs-3"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <a/>
                  </template>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div id="floating-btn-wrapper">
          <b-button variant="outline-success"
                    class="blurry-bg w-100"
                    size="lg" type="submit">
            <i class="bx bx-check"></i>
          </b-button>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
.blurry-bg {
  backdrop-filter: blur(10px);
}

#floating-btn-wrapper {
  position: absolute;
  bottom: 2%;
}
</style>