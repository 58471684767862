<template>
  <PageHeader title="General" :items="items"></PageHeader>
  <ManageRole ref="manageRole"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-md-3">
                <div class="search-box">
                  <input v-model="search" type="text" class="form-control search"
                         placeholder="Search for roles...">
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-grid gap-2">
                  <b-button variant="soft-info" class="w-lg waves-effect waves-light"
                            @click="createRole()">Create
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 gy-3 pb-3">
          <div class="col mb-2" v-for="role in rolesComputed" :key="role.id">
            <b-card class="mb-0"><!----><!---->
              <h4 class="card-title">{{ role.name }}</h4>
              <p class="card-text text-muted">
                {{ role.description || '-' }}
              </p>
              <div class="card-footer d-flex justify-content-between align-items-center pb-0 px-0">
                <span>12 users</span>
                <span
                    @click="this.$refs.manageRole.show(role)"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#ManageRoleCanvas"
                    class="text-secondary cursor-pointer">
                  Manage now
                  <i class="mdi mdi-chevron-right fs-5 align-middle"></i>
                </span>
              </div>
            </b-card>
          </div>
        </div>
        <h6>Showing {{ rolesComputed.length }} out of {{ roles.length }} roles</h6>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import PageHeader from "../../../../components/page-header.vue";
import axios from "axios";
import ManageRole from "@/views/pages/general/role/modals/ManageRole.vue";


export default {
  name: "StationsList",
  data() {
    return {
      roles: [],
      search: '',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Role List",
          active: true,
        },
      ]
    }
  },
  methods: {
    async getRoles() {
      try {
        let response = await axios.get(`/user/roles/`);
        this.roles = response.data.results;
      } catch (e) {
        console.log(e)
      }
    },
    async createRole() {
      const {value: user_input} = await Swal.fire({
        title: 'Create Role',
        html:
            `<input id="role_name" class="form-control w-75 m-auto mt-2" placeholder="Role Name">
                    <input id="role_description" class="form-control w-75 m-auto mt-2" placeholder="Role Description">`,
        focusConfirm: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          let name = document.getElementById('role_name').value
          let description = document.getElementById('role_description').value
          if (name.trim().length === 0)
            return Swal.showValidationMessage(`Please enter a role name`)
          else {
            if (this.roles.filter(role => role.name === name).length !== 0)
              return Swal.showValidationMessage(`A role with this name already exists`)
            else {
              return {name, description}
            }
          }
        }
      })

      if (user_input.name.trim().length !== 0) {
        let response = await axios.post(`/user/roles/create/`, {
          name: user_input.name.trim(),
          description: user_input.description.trim(),
        });
        await this.getRoles()
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await Toast.fire({
          icon: response.status === 201 ? 'success' : 'error',
          title: response.status ? 'Role created successfully' : 'Something went wrong'
        })
      }
    },
    async updateRole(id, name, description) {
      let response = await axios.put(`/user/roles/${id}/update/`, {name: name, description: description});
      await this.getRoles()
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      await Toast.fire({
        icon: response.status === 200 ? 'success' : 'error',
        title: response.status === 200 ? 'Role updated successfully' : 'Something went wrong'
      })
    },

    async deleteRoleConfirmation(role) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete ${(role.name).toString()}`,
        text: 'Deleting this role will remove all of its information from our database',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type ${(role.name).toString()} to confirm`,
        input: 'email',
        inputPlaceholder: `${(role.name).toString()}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === (role.name).toString()) {
              resolve(this.deleteRole(role.id))
            } else {
              resolve('Role name did not match :)')
            }
          })
        }
      });
    },
    deleteRole(id) {
      axios.delete(`/user/roles/${id}/delete/`)
          .then(response => {
            this.getRoles()
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            Toast.fire({
              icon: response.status === 204 ? 'success' : 'error',
              title: response.status === 204 ? 'Role deleted successfully' : 'Something went wrong'
            })
          })
          .catch(error => {
            this.showResponse(error.response)
          })
    },


    async showResponse(response, successMessage) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      await Toast.fire({
        icon: response.status === 201 ? 'success' : 'error',
        title: response.status ? successMessage : 'Something went wrong'
      })
    }
  },
  async mounted() {
    await this.getRoles()
  },
  computed: {
    rolesComputed() {
      if (this.search.trim().length !== 0) {
        return this.roles.filter(role => role.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return this.roles
      }
    }
  },
  components: {
    PageHeader,
    ManageRole
  }
}
</script>

<style scoped>
</style>